import { Vec3 } from 'playcanvas';

import { Camera } from './camera';

const fromWorldPoint = new Vec3();
const toWorldPoint = new Vec3();
const worldDiff = new Vec3();

// calculate the distance between two 2d points
const dist = (x0: number, y0: number, x1: number, y1: number) => Math.sqrt((x1 - x0) ** 2 + (y1 - y0) ** 2);

class PointerController {
    update: (deltaTime: number) => void;
    destroy: () => void;

    constructor(camera: Camera, target: HTMLElement) {

        const orbit = (dx: number, dy: number) => {
            const azim = camera.azim - dx * camera.scene.config.controls.orbitSensitivity;
            const elev = camera.elevation - dy * camera.scene.config.controls.orbitSensitivity;
            camera.setAzimElev(azim, elev);
        };

        const pan = (x: number, y: number, dx: number, dy: number) => {
            // For panning to work at any zoom level, we use screen point to world projection
            // to work out how far we need to pan the pivotEntity in world space
            const c = camera.entity.camera;
            const distance = camera.distanceTween.value.distance * camera.sceneRadius / camera.fovFactor;

            c.screenToWorld(x, y, distance, fromWorldPoint);
            c.screenToWorld(x - dx, y - dy, distance, toWorldPoint);

            worldDiff.sub2(toWorldPoint, fromWorldPoint);
            worldDiff.add(camera.focalPoint);

            camera.setFocalPoint(worldDiff);
        };

        const zoom = (amount: number) => {
            camera.setDistance(camera.distance - (camera.distance * 0.999 + 0.001) * amount * camera.scene.config.controls.zoomSensitivity, 2);
        };

        // mouse state
        const buttons = [false, false, false];
        let x: number, y: number;

        // touch state
        let touches: { id: number, x: number, y: number}[] = [];
        let midx: number, midy: number, midlen: number;

        const pointerdown = (event: PointerEvent) => {
            if (event.pointerType === 'mouse') {
                if (buttons.every(b => !b)) {
                    target.setPointerCapture(event.pointerId);
                }
                buttons[event.button] = true;
                x = event.offsetX;
                y = event.offsetY;
            } else if (event.pointerType === 'touch') {
                if (touches.length === 0) {
                    target.setPointerCapture(event.pointerId);
                }
                touches.push({
                    x: event.offsetX,
                    y: event.offsetY,
                    id: event.pointerId
                });

                if (touches.length === 2) {
                    midx = (touches[0].x + touches[1].x) * 0.5;
                    midy = (touches[0].y + touches[1].y) * 0.5;
                    midlen = dist(touches[0].x, touches[0].y, touches[1].x, touches[1].y);
                }
            }
        };

        const pointerup = (event: PointerEvent) => {
            if (event.pointerType === 'mouse') {
                buttons[event.button] = false;
                if (buttons.every(b => !b)) {
                    target.releasePointerCapture(event.pointerId);
                }
            } else {
                touches = touches.filter(touch => touch.id !== event.pointerId);
                if (touches.length === 0) {
                    target.releasePointerCapture(event.pointerId);
                }
            }
        };

        const pointermove = (event: PointerEvent) => {
            if (event.pointerType === 'mouse') {
                const dx = event.offsetX - x;
                const dy = event.offsetY - y;
                x = event.offsetX;
                y = event.offsetY;

                // right button can be used to orbit with ctrl key and to zoom with alt | meta key
                const mod = buttons[2] ?
                    (event.shiftKey || event.ctrlKey ? 'orbit' :
                        (event.altKey || event.metaKey ? 'zoom' : null)) :
                    null;

                if (mod === 'orbit' || (mod === null && buttons[0])) {
                    orbit(dx, dy);
                } else if (mod === 'zoom' || (mod === null && buttons[1])) {
                    zoom(dy * -0.02);
                } else if (mod === 'pan' || (mod === null && buttons[2])) {
                    pan(x, y, dx, dy);
                }
            } else {
                if (touches.length === 1) {
                    const touch = touches[0];
                    const dx = event.offsetX - touch.x;
                    const dy = event.offsetY - touch.y;
                    touch.x = event.offsetX;
                    touch.y = event.offsetY;
                    orbit(dx, dy);
                } else if (touches.length === 2) {
                    const touch = touches[touches.map(t => t.id).indexOf(event.pointerId)];
                    touch.x = event.offsetX;
                    touch.y = event.offsetY;

                    const mx = (touches[0].x + touches[1].x) * 0.5;
                    const my = (touches[0].y + touches[1].y) * 0.5;
                    const ml = dist(touches[0].x, touches[0].y, touches[1].x, touches[1].y);

                    pan(mx, my, (mx - midx), (my - midy));
                    zoom((ml - midlen) * 0.01);

                    midx = mx;
                    midy = my;
                    midlen = ml;
                }
            }
        };

        // fuzzy detection of mouse wheel events vs trackpad events
        const isMouseEvent = (deltaX: number, deltaY: number) => {
            return (Math.abs(deltaX) > 50 && deltaY === 0) ||
                   (Math.abs(deltaY) > 50 && deltaX === 0) ||
                   (deltaX === 0 && deltaY !== 0) && !Number.isInteger(deltaY);
        };

        const wheel = (event: WheelEvent) => {
            const { deltaX, deltaY } = event;

            if (isMouseEvent(deltaX, deltaY)) {
                zoom(deltaY * -0.002);
            } else if (event.ctrlKey || event.metaKey) {
                zoom(deltaY * -0.02);
            } else if (event.shiftKey) {
                pan(event.offsetX, event.offsetY, deltaX, deltaY);
            } else {
                orbit(deltaX, deltaY);
            }

            event.preventDefault();
        };

        // FIXME: safari sends canvas as target of dblclick event but chrome sends the target element
        const canvas = camera.scene.app.graphicsDevice.canvas;

        const dblclick = (event: globalThis.MouseEvent) => {
            if (event.target === target || event.target === canvas) {
                camera.pickFocalPoint(event.offsetX, event.offsetY);
            }
        };

        // key state
        const keys: any = {
            ArrowUp: 0,
            ArrowDown: 0,
            ArrowLeft: 0,
            ArrowRight: 0,
            W: 0, // 앞으로 이동 (ArrowUp)
            S: 0, // 뒤로 이동 (ArrowDown)
            A: 0, // 왼쪽 이동 (ArrowLeft)
            D: 0  // 오른쪽 이동 (ArrowRight)
        };

        // const keydown = (event: KeyboardEvent) => {
        //     const key = event.key.toUpperCase();
        
        //     // WASD 키 처리
        //     if (key === 'W') {
        //         keys.W = 1; // 앞으로 이동
        //     } else if (key === 'S') {
        //         keys.S = 1; // 뒤로 이동
        //     } else if (key === 'A') {
        //         keys.A = 1; // 왼쪽 이동
        //     } else if (key === 'D') {
        //         keys.D = 1; // 오른쪽 이동
        //     }
        
        //     // 방향키 처리
        //     if (keys.hasOwnProperty(event.key)) {
        //         keys[event.key] = 1;
        //     }
        // };
        
        // const keyup = (event: KeyboardEvent) => {
        //     const key = event.key.toUpperCase();
        
        //     // WASD 키 처리
        //     if (key === 'W') {
        //         keys.W = 0; // 앞으로 이동 중지
        //     } else if (key === 'S') {
        //         keys.S = 0; // 뒤로 이동 중지
        //     } else if (key === 'A') {
        //         keys.A = 0; // 왼쪽 이동 중지
        //     } else if (key === 'D') {
        //         keys.D = 0; // 오른쪽 이동 중지
        //     }
        
        //     // 방향키 처리
        //     if (keys.hasOwnProperty(event.key)) {
        //         keys[event.key] = 0;
        //     }
        // };


        const keydown = (event: KeyboardEvent) => {
            if (event.code === 'Space') {
                jump(); // 스페이스바 눌렀을 때 점프
            }
            // 키보드 물리적 위치 (event.code) 기반 처리
            switch (event.code) {
                case 'KeyW': // W 키
                    keys.W = 1;
                    break;
                case 'KeyS': // S 키
                    keys.S = 1;
                    break;
                case 'KeyA': // A 키
                    keys.A = 1;
                    break;
                case 'KeyD': // D 키
                    keys.D = 1;
                    break;
                case 'KeyQ': // Q 키
                    keys.Q = 1;
                    break;
                case 'KeyE': // E 키
                    keys.E = 1;
                    break;
                case 'ArrowUp': // 화살표 위
                    keys.ArrowUp = 1;
                    break;
                case 'ArrowDown': // 화살표 아래
                    keys.ArrowDown = 1;
                    break;
                case 'ArrowLeft': // 화살표 왼쪽
                    keys.ArrowLeft = 1;
                    break;
                case 'ArrowRight': // 화살표 오른쪽
                    keys.ArrowRight = 1;
                    break;
            }
        };

        const keyup = (event: KeyboardEvent) => {
            switch (event.code) {
                case 'KeyW': // W 키
                    keys.W = 0;
                    break;
                case 'KeyS': // S 키
                    keys.S = 0;
                    break;
                case 'KeyA': // A 키
                    keys.A = 0;
                    break;
                case 'KeyD': // D 키
                    keys.D = 0;
                    break;
                case 'KeyQ': // Q 키
                    keys.Q = 0;
                    break;
                case 'KeyE': // E 키
                    keys.E = 0;
                    break;
                case 'ArrowUp': // 화살표 위
                    keys.ArrowUp = 0;
                    break;
                case 'ArrowDown': // 화살표 아래
                    keys.ArrowDown = 0;
                    break;
                case 'ArrowLeft': // 화살표 왼쪽
                    keys.ArrowLeft = 0;
                    break;
                case 'ArrowRight': // 화살표 오른쪽
                    keys.ArrowRight = 0;
                    break;
            }
        };
        
        


        // this.update = (deltaTime: number) => {
        //     const x = keys.ArrowRight - keys.ArrowLeft;
        //     const z = keys.ArrowDown - keys.ArrowUp;

        //     if (x || z) {
        //         const factor = deltaTime * camera.flySpeed;
        //         const worldTransform = camera.entity.getWorldTransform();
        //         const xAxis = worldTransform.getX().mulScalar(x * factor);
        //         const zAxis = worldTransform.getZ().mulScalar(z * factor);
        //         const p = camera.focalPoint.add(xAxis).add(zAxis);
        //         camera.setFocalPoint(p);
        //     }
        // };

        this.update = (deltaTime: number) => {
            // WASD 키 이동 처리
            let x = keys.D - keys.A; // D: 오른쪽 이동, A: 왼쪽 이동
            let z = keys.S - keys.W; // S: 앞으로 이동, W: 뒤로 이동
        
            // Q와 E 키 대각선 이동 처리
            if (keys.Q) {
                x -= 1; // 왼쪽 대각선 (X축 감소)
                z -= 1; // 앞으로 대각선 (Z축 증가)
            }
            if (keys.E) {
                x += 1; // 오른쪽 대각선 (X축 증가)
                z -= 1; // 앞으로 대각선 (Z축 증가)
            }
        
            // 화살표 키 처리
            const rotateX = keys.ArrowRight - keys.ArrowLeft; // 좌우 회전
            const rotateY = keys.ArrowDown - keys.ArrowUp;   // 상하 회전 (반대로)
        
            // 이동 처리 (WASD + Q/E 키)
            if (x || z) {
                const factor = deltaTime * camera.flySpeed;
                const worldTransform = camera.entity.getWorldTransform();
                const xAxis = worldTransform.getX().mulScalar(x * factor);
                const zAxis = worldTransform.getZ().mulScalar(z * factor);
                const p = camera.focalPoint.add(xAxis).add(zAxis);
                camera.setFocalPoint(p);
            }
        
            // 좌우 회전 처리 (화살표 좌/우 키)
            if (rotateX) {
                const rotationSpeedMultiplier = 7; // 회전 속도 배율
                const dx = rotateX * camera.scene.config.controls.orbitSensitivity * rotationSpeedMultiplier;
                camera.setAzimElev(camera.azim - dx, camera.elevation);
            }
        
            // 상하 회전 처리 (화살표 위/아래 키, 반대로)
            if (rotateY) {
                const rotationSpeedMultiplier = 7; // 상하 회전 속도 배율
                const dy = rotateY * camera.scene.config.controls.orbitSensitivity * rotationSpeedMultiplier;
                camera.setAzimElev(camera.azim, camera.elevation - dy);
            }
        };
        
        let isJumping = false; // 점프 상태를 추적
        let velocity = 0; // 초기 속도
        let positionY = 0; // 현재 Y 위치 (상대값)
        const gravity = -9.8; // 중력 가속도
        const jumpVelocity = 5; // 점프 시작 속도
        const maxJumpHeight = 0.8; // 최대 점프 높이
        const groundY = camera.focalPoint.clone().y; // 초기 땅의 Y 위치 (절대값)
        const baseY = 0; // 기본 Y 위치



        const jump = () => {
            if (isJumping) return; // 이미 점프 중이면 무시
            camera.setJumping(true); // 점프 상태 시작
            isJumping = true;
            velocity = jumpVelocity; // 초기 속도 설정
            console.log(`KTH, base Y: ${camera.focalPoint.clone().y}, ${groundY}`);
            const update = (deltaTime: number) => {
                if (!isJumping) return;
        
                // 속도 및 위치 계산
                velocity += gravity * deltaTime;
                positionY += velocity * deltaTime;
        
                // 최대 높이를 초과하지 않도록 제한
                if (positionY > maxJumpHeight) {
                    positionY = maxJumpHeight;
                    velocity = 0; // 정점에서 속도 0으로 설정
                }
        
                // 카메라 위치 업데이트
                const currentFocalPoint = camera.focalPoint.clone();
                camera.setFocalPoint(new Vec3(currentFocalPoint.x, baseY + positionY, currentFocalPoint.z));
        
                // 바닥에 도달하면 점프 종료
                if (positionY <= 0) {
                    positionY = 0; // 바닥으로 정렬
                    camera.setJumping(false); // 점프 상태 해제
                    isJumping = false;
        
                    // 초기 위치로 강제 복원
                    camera.setFocalPoint(new Vec3(currentFocalPoint.x, baseY, currentFocalPoint.z)); 
                    console.log(`KTH, Restored Y: ${camera.focalPoint.clone().y}`);
                    return;
                }
        
                // 다음 프레임 요청
                requestAnimationFrame(() => update(0.016)); // 60fps 기준 deltaTime = 1/60
            };
        
            update(0.016); // 점프 시작
        };


        let destroy: () => void = null;

        const wrap = (target: any, name: string, fn: any, options?: any) => {
            const callback = (event: any) => {
                camera.scene.events.fire('camera.controller', name);
                fn(event);
            };
            target.addEventListener(name, callback, options);
            destroy = () => {
                destroy?.();
                target.removeEventListener(name, callback);
            };
        };

        wrap(target, 'pointerdown', pointerdown);
        wrap(target, 'pointerup', pointerup);
        wrap(target, 'pointermove', pointermove);
        wrap(target, 'wheel', wheel, { passive: false });
        wrap(target, 'dblclick', dblclick);
        wrap(document, 'keydown', keydown);
        wrap(document, 'keyup', keyup);

        this.destroy = destroy;
    }
}

export { PointerController };
